.proposal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  opacity: 0.95;
  padding: 4rem 5rem;
  /* max-width: 50rem; */
}

.top-corners {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.bottom-corners {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.proposal-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* max-width: 50rem; */
}

.input-width {
  width: 100%;
}

.input-width-phone,
.input-width-date {
  margin-right: auto;
  width: 12rem;
}

h1 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.phone-label {
  margin-right: auto;
  font-size: 0.5rem;
}
