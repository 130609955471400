.signup-container {
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0.95;
  border-radius: 25px;
}

.signup-styles {
  padding: 3rem 5rem 3rem 5rem;
  width: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  margin: 10rem auto 0;
}

h1 {
  text-align: center;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
