.login-container {
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0.95;
  border-radius: 25px;
}

.login-styles {
  padding: 5rem;
  width: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  margin: 15rem auto 0;
}

.submit-button {
  margin-bottom: 2.3rem;
}

/* .hyperlink-transition {
  transition: all ease 1s;
} */

/* .hyperlink-transition:hover {
  border-bottom: 1px solid blue;
} */
