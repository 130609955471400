.component {
    margin-left: 70px;
    margin-right: 70px;
  }
  
  .bold {
    font-weight: bold;
    margin-bottom: -1px;
  }
  .middle {
    margin-left: 25%;
    text-align: center;
  }
  
  .initialsDiv {
    text-align: right;
    margin-top: -40px;
  }
  
  .head {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 0.25fr 0.75fr;
    grid-gap: 60px;
  }
  .logo {
    width: 120%;
    margin-bottom: 20px;
  }
  .title {
    font-size: 40px;
    margin-left: 80px;
    margin-bottom: 20px;
    text-decoration: underline;
    font-weight: bold;
  }
  .info {
    font-size: 25px;
  }
  
  .agreement {
    font-size: 20px;
    line-height: 1.6;
  }
  
  .description-short {
    float: left;
    width: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 21px;
    text-decoration: underline;
    font-weight: bold;
  }
  .description-price {
    float: right;
    width: 50%;
    text-align: right;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 21px;
    text-decoration: underline;
    font-weight: bold;
  }
  
  .sign {
    page-break-inside: avoid;
    font-size: 20px;
    line-height: 1.6;
    margin-left: 0%;
    width: 100%;
    height: 400px;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 3fr 1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
      "head head head head"
      "sub sub sub sub"
      "company company customer customer";
  }
  .grid-head {
    font-weight: bold;
    text-decoration: underline;
    grid-area: head;
    justify-self: center;
  }
  .grid-sub {
    grid-area: sub;
  }
  .grid-company {
    grid-area: company;
  }
  .grid-customer {
    grid-area: customer;
  }
  
  .footer,
  .header,
  .footer-space,
  .header-space {
    height: 100px;
    font-size: 20px;
    width: 900px;
    text-align: center;
  }
  
  .pageDiv {
    overflow: auto;
    float: right;
  }
  @media screen {
    .footer {
      display: none;
    }
    .header {
      display: none;
    }
  }
  @media print {
    .footer {
      position: fixed;
      bottom: 0px;
    }
    .header {
      position: fixed;
      top: 90px;
    }
  }
  