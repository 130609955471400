.btn-print {
  display: block;
  width: 150px;
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 2rem;
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  outline: none;
  color: #fff;
  border: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 7px;
  background-color: #d31c1cbc;
}

.print-component {
  display: none;
}
