.shadowhover {
    box-shadow: 1px 1px 50px -15px black;
}

.shadowhover:hover {
    box-shadow: 1px 1px 50px -5px black;
}

.btnshadow {
    box-shadow: 2px 2px 20px -10px black;
}

.redirect:hover {
   text-decoration: underline; 
}

.delete:hover {
    color: red;
}