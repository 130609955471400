body {
  background-color: #ededed;
  background-attachment: fixed;
  background-size: cover;
  overflow-x: hidden;
}

:root {
  --dark-blue-2: #446e8f;
  --dark-blue: #01579b;
  --medium-blue: #03a9f4;
  --light-blue: #b3e5fc;
  --grey-back: #585858;
  --medium-purple: #2703f4;
  --dark-purple: #23019b;
}

.submit-margin {
  display: flex;
  justify-content: center;
}

.submit-button {
  display: inline-block;
  /* overflow: hidden; */
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  /* border: 1px solid #005ce6; */
  border: 1px solid var(--dark-blue);
  border-radius: 10px;
  padding: 0.4em 1em;
  margin-top: 30px;
  font-size: 2rem;
  font-weight: 350;
  background-color: var(--dark-blue);
  transition: all 0.2s ease;
  cursor: pointer;
}

.submit-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.new-proposal-button {
  display: inline-block;
  /* overflow: hidden; */
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  /* border: 1px solid #005ce6; */
  border: 1px solid var(--dark-blue);
  border-radius: 7px;
  padding: 0.4em 1em;
  margin-top: 30px;
  font-size: 1.5rem;
  font-weight: 350;
  background-color: var(--dark-blue);
  transition: all 0.2s ease;
  cursor: pointer;
}

.new-proposal-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.back-button {
  display: inline-block;
  /* overflow: hidden; */
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  /* border: 1px solid #80b3ff; */
  border: 1px solid var(--grey-back);
  border-radius: 10px;
  padding: 0.4em 1em;
  margin-top: 30px;
  font-size: 2rem;
  font-weight: 350;
  /* background-color: #80b3ff; */
  background-color: var(--grey-back);
  transition: all 0.2s ease;
  cursor: pointer;
}

.back-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.choices-button {
  display: inline-block;
  /* overflow: hidden; */
  /* letter-spacing: 1px; */
  /* text-transform: uppercase; */
  text-decoration: none;
  color: #000;
  background-color: transparent;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 0.4em 1em;
  margin-top: 30px;
  font-size: 2rem;
  font-weight: 350;
  background-color: grey;
  transition: all 0.2s ease;
  cursor: pointer;
}

.back-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.choice-unclicked-button {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 0.4em 1em;
  margin-top: 30px;
  font-size: 2rem;
  font-weight: 350;
  background-color: grey;
  transition: all 0.2s ease;
  cursor: pointer;
}

.choice-clicked-button {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  /* border: 1px solid blue; */
  border: 1px solid var(--dark-blue);
  border-radius: 10px;
  padding: 0.4em 1em;
  margin-top: 30px;
  font-size: 2rem;
  font-weight: 350;
  /* background-color: blue; */
  background-color: var(--dark-blue);
  transition: all 0.1s ease;
  cursor: pointer;
}

.choice-unclicked-button:hover {
  color: #fff;
}

.choice-clicked-button:hover {
  color: #fff;
}

.choice-clicked-button:focus,
.choice-unclicked-button:focus {
  outline: none;
}

/* ---------------- Generate PDF Buttons ----------------- */

.PDF-back-button {
  display: inline-block;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  /* border: 1px solid #cce0ff; */
  border: 1px solid var(--grey-back);
  border-radius: 10px;
  /* padding: 0.6em 1.4em; */
  width: 200px;
  height: 100px;
  font-size: 30px;
  font-weight: 350;
  /* background-color: #cce0ff; */
  background-color: var(--grey-back);
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.PDF-back-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.PDF-generate-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border: 1px solid var(--dark-blue);
  border-radius: 10px;
  /* padding: 0.6em 1.4em; */
  width: 200px;
  height: 100px;
  font-size: 27px;
  font-weight: 350;
  background-color: var(--dark-blue);
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.PDF-generate-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.change-order {
  display: inline-block;
  /* overflow: hidden; */
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  background-color: transparent;
  border: 1px solid rgb(41, 43, 44);
  border-radius: 10px;
  padding: 0.4em 1em;
  margin-top: 30px;
  font-size: 2rem;
  font-weight: 350;
  background-color: rgb(41, 43, 44);
  transition: all 0.2s ease;
  cursor: pointer;
}

.change-order:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

/* ---------------- Email Buttons ----------------- */

/* boxShadow: '2px 2px 20px -10px black', */

.email-back-button {
  display: inline-block;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  /* border: 1px solid #cce0ff; */
  border: 1px solid var(--grey-back);
  border-radius: 10px;
  width: 200px;
  height: 100px;
  margin-top: 30px;
  font-size: 30px;
  font-weight: 350;
  /* background-color: #cce0ff; */
  background-color: var(--grey-back);
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.email-back-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.email-submit-button {
  display: inline-block;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  /* border: 1px solid #cce0ff; */
  border: 1px solid var(--light-blue);
  border-radius: 10px;
  width: 200px;
  height: 100px;
  margin-top: 30px;
  font-size: 30px;
  font-weight: 350;
  /* background-color: #cce0ff; */
  background-color: var(--light-blue);
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.email-submit-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.restart-proposal-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border: 1px solid #80b3ff;
  border-radius: 10px;
  width: 200px;
  height: 100px;
  margin-top: 30px;
  font-size: 27px;
  font-weight: 350;
  background-color: #80b3ff;
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.restart-proposal-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.combine-projects-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border: 1px solid #668fcc;
  border-radius: 10px;
  width: 200px;
  height: 100px;
  margin-top: 30px;
  font-size: 27px;
  font-weight: 350;
  background-color: #668fcc;
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.combine-projects-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.send-email-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border: 1px solid var(--dark-blue);
  border-radius: 10px;
  width: 200px;
  height: 100px;
  margin-top: 30px;
  font-size: 27px;
  font-weight: 350;
  background-color: var(--dark-blue);
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.send-email-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

/* ------- Download PDF Button ------- */

.download-pdf-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border: 1px solid var(--dark-blue-2);
  border-radius: 10px;
  width: 230px;
  height: 100px;
  margin-top: 30px;
  font-size: 27px;
  font-weight: 350;
  background-color: var(--dark-blue-2);
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.download-pdf-button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

/* --------- Upload PDF Label --------- */

.upload-pdf-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border: 1px solid var(--dark-blue-2);
  border-radius: 10px;
  width: 230px;
  height: 100px;
  margin-top: 30px;
  font-size: 27px;
  font-weight: 350;
  background-color: var(--dark-blue-2);
  box-shadow: 2px 2px 20px -10px #000;
  transition: all 0.2s ease;
  cursor: pointer;
}

.upload-pdf-label:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

/* .upload-pdf-span {

} */

/* --------- Image Hover Effect --------- */

.image-hover {
  transition: all 0.2s;
}

.image-hover:hover {
  transform: scale(1.05);
}
