.modal {
  text-align: center;
}

.modal-content {
  border-radius: 10px;
  border: none;
}

.modal-dialog {
  text-align: left;
  vertical-align: middle;
}

.modal-dialog {
  width: auto;
}

.calculator-modal {
  width: 100%;
  border-radius: 10px;
  transition: 200ms ease-in-out;
}

.calculator {
  width: 300px;
  height: 40%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-sizing: 0 5px 30px -5px rgba(0, 0, 0, 0.6);
}

/* .calculator.active {
  transform: scale(1);
  display: block;
} */

/* Display */
.calculator-display {
  position: relative;
  background: black;
  color: white;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 12px;
  border-radius: 10px 10px 0 0;
}

.calculator-display h1 {
  margin: 0;
  padding: 25px;
  font-size: 45px;
  font-family: 'Lucida Console', sans-serif;
  font-weight: 100;
  overflow-x: auto;
}

/* .calculator-close-btn {
    background: rgb(124, 116, 116);
    width: 150px;
    position: absolute;
    right: 0;
    bottom: 79%; 
    border-radius: 0 10px 0 0;
    text-align: center;
  } */

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Buttons */
.calculator-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 10px;
}

button {
  min-height: 50px;
  font-size: 20px;
  font-weight: 300;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: rgb(199, 199, 199);
}

button:hover {
  filter: brightness(110%);
}

button:hover {
  transform: translateY(1px);
}

button:focus {
  outline: none;
}

.operator {
  background: grey;
  color: white;
}

.clear {
  background: red;
  color: white;
}

.clear:hover {
  filter: brightness(90%);
}

.equal-sign {
  grid-column: -2;
  grid-row: 2 / span 4;
  background: rgb(13, 206, 87);
}

.close-calculator {
  grid-row: 6;
  grid-column: span 4;
  font-weight: 600;
}
