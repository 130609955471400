.email-form-container {
  position: relative;
  left: 20%;
  width: 80%;
  background-color: #fff;
  padding: 3rem;
  border-radius: 10px;
  width: 60%;
  /* height: 100vh; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
}

.contact-form {
  display: flex;
  flex-direction: column;
}

/* .contact-form {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 600px;
} */

.email-heading {
  text-align: center;
}

.email-input {
  width: 100%;
}
.email-textarea {
  width: 100%;
}

input {
  padding-left: 0.3rem;
}

textarea {
  padding: 0.3rem;
}

input:focus,
textarea:focus {
  outline: none;
}

.submitDiv {
  text-align: center;
}
.submit {
  font-size: x-large;
  padding: 1rem;
  border-radius: 0.3rem;
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  margin: 3rem auto 0;
  transition: all 0.2s;
}

.submit:hover {
  /* opacity: 0.6; */
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
